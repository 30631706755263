import { type RouteRecordRaw } from 'vue-router'

export const settingsRouterBrand: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () =>
          import(
            '../components/brand/editors/general/BrandGeneralSettings.vue'
          ),
        name: `brand.settings.general`,
        path: 'general',
      },
      {
        component: async () =>
          import('../components/brand/editors/BrandLinks.vue'),
        name: `brand.settings.links`,
        path: 'links',
      },
      {
        component: async () =>
          import('../components/brand/editors/UserNotifications.vue'),
        name: `brand.settings.notifications`,
        path: 'notifications',
      },
      {
        component: async () =>
          import(
            '../components/brand/editors/sms-marketing/SMSMarketingEditor.vue'
          ),
        name: `brand.settings.sms-marketing`,
        path: 'sms-marketing',
      },
    ].map((route) => ({
      ...route,
      meta: {
        locationRoute: 'location.settings.general',
        redirectToLocation: true,
      },
    })),
    component: async () => import('../views/BrandSettingsView.vue'),
    meta: {
      redirectToLocation: true,
      title: 'settings-brand',
    },
    name: 'brand.settings',
    path: 'settings',
    props: {
      pageName: 'settings',
    },
  },
]

export const settingsRouterLocation: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () =>
          import(
            '../components/location/editors/general/LocationGeneralSettingsEditor.vue'
          ),
        name: `location.settings.general`,
        path: 'general',
      },
      {
        component: async () =>
          import('../components/location/editors/AddressEditor.vue'),
        name: `location.settings.address`,
        path: 'address',
      },
      {
        component: async () =>
          import('../components/location/editors/LocationLinks.vue'),
        name: `location.settings.links`,
        path: 'links',
      },
      {
        component: async () =>
          import('../components/location/editors/ContactInfoEditor.vue'),
        name: `location.settings.contact`,
        path: 'contact',
      },
      {
        component: async () =>
          import('../components/location/editors/FulfillmentEditor.vue'),
        name: `location.settings.fulfillment`,
        path: 'fulfillment',
      },
      {
        component: async () =>
          import(
            '../components/location/editors/integrations/IntegrationsEditor.vue'
          ),
        name: `location.settings.integrations`,
        path: 'integrations',
      },
      {
        component: async () =>
          import('../components/location/editors/PaymentsEditor.vue'),
        meta: {
          roles: ['pb:*'],
        },
        name: `location.settings.payments`,
        path: 'payments',
      },
      {
        component: async () =>
          import('../components/location/editors/FeaturesEditor.vue'),
        meta: {
          roles: ['pb:*'],
        },
        name: `location.settings.features`,
        path: 'features',
      },
      {
        component: async () =>
          import('../components/location/editors/ListingsEditor.vue'),
        meta: {
          roles: ['pb:*'],
        },
        name: `location.settings.listings`,
        path: 'listings',
      },
    ].map((route) => ({
      ...route,
      meta: {
        ...route.meta,
        brandRedirect: 'brand.settings.general',
        isLocationPage: true,
      },
    })),
    component: async () => import('../views/LocationSettingsView.vue'),
    meta: {
      scopes: ['locations:update'],
      title: 'settings',
    },
    name: `location.settings`,
    path: 'settings',
  },
]
