<template>
  <div class="dual-tone-split-pane">
    <div class="dual-tone-split-pane__left-panel">
      <slot name="lhs" />
    </div>
    <div class="dual-tone-split-pane__right-panel">
      <slot name="rhs" />
    </div>
  </div>
</template>

<style lang="scss">
@use '@/styles/breakpoints';

.dual-tone-split-pane {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);

  min-height: 100%;

  @include breakpoints.respond-to('md-and-up') {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__left-panel {
    padding: 24px 16px;
    background-color: var(--background-primary);

    @include breakpoints.respond-to('md-and-up') {
      padding: 48px;
    }
  }

  &__right-panel {
    padding: 24px 16px;
    border-top: 1px dashed var(--background-divider);

    @include breakpoints.respond-to('md-and-up') {
      padding: 48px;
      border-top: none;
      border-left: 1px dashed var(--background-divider);
    }
  }
}
</style>
