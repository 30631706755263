import { type Reactive, type Ref, watch } from 'vue'

import type { PaginatedRequestParams } from '@/core'

export const usePaginationWatchers = (
  handler: () => Promise<void>,
  {
    query,
    requestParams,
  }: {
    query: Ref<string>
    requestParams: Reactive<PaginatedRequestParams>
  }
): void => {
  watch(query, () => {
    requestParams.page = 1
    void handler()
  })

  watch(requestParams, () => {
    void handler()
  })
}
