<template>
  <div class="full-page-editor">
    <div class="full-page-editor__content">
      <header class="full-page-editor__content-head">
        <slot name="top-bar">
          <DefaultFullPageTopBar :title="title" @close="$emit('close')" />
        </slot>
      </header>

      <div class="full-page-editor__content-body">
        <slot />
      </div>
    </div>

    <slot name="dialog" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import DefaultFullPageTopBar from './DefaultFullPageTopBar.vue'

const props = withDefaults(
  defineProps<{
    layer?: 'editor' | 'overlay'
    level?: number
    title?: string
  }>(),
  {
    layer: undefined,
    level: 0,
    title: undefined,
  }
)

defineEmits<{ close: [] }>()

const topOffset = computed(() => {
  return `${props.level * 8}px`
})

const zIndexBase = computed(() => {
  switch (props.layer) {
    case 'overlay':
      return 140 + props.level
    case 'editor':
      return 130 + props.level
    default:
      return 120 + props.level
  }
})
</script>

<style lang="scss" scoped>
.full-page-editor {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: v-bind(zIndexBase);
  background-color: rgba(#000000, 50%);

  &__content {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: v-bind(topOffset);
    left: 0;
    height: calc(100% - v-bind(topOffset));
    width: 100%;
    background-color: var(--background-primary);
  }

  &__content-head {
    z-index: calc(v-bind(zIndexBase) + 1);
  }

  &__content-body {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
