<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="{ name: 'brand.home', params: { brandId: brand.id } }"
  >
    <OwnCard
      class="brand-link"
      el="a"
      :href="href"
      control
      @click="onNavItemClick($event, navigate)"
    >
      <span class="w-full flex-row gap-3 align-center">
        <span v-if="brand.assets.logo" class="brand-link__logo-container">
          <img
            class="brand-link__logo"
            :src="brand.assets.logo"
            :alt="brand.name"
          />
        </span>
        <span
          v-else
          class="brand-link__logo-placeholder flex-col align-center justify-center"
        >
          <PhStorefront size="16" weight="bold" />
        </span>

        <OwnType
          class="text--truncate"
          :text="brand.name"
          el="span"
          variant="subtitle"
          color="primary"
        />
      </span>
    </OwnCard>
  </router-link>
</template>

<script lang="ts" setup>
import { PhStorefront } from '@phosphor-icons/vue'

import { OwnCard, OwnType } from '@/ui'

import { Brand } from '../types'

defineProps<{
  brand: Brand
}>()

const emit = defineEmits(['navigate'])

const onNavItemClick = (e: MouseEvent, navigate: (e: MouseEvent) => void) => {
  emit('navigate')
  navigate(e)
}
</script>

<style lang="scss" scoped>
.brand-link {
  width: 100%;
  height: 50px;
  padding: 8px 12px;

  &__logo-container {
    width: 32px;
    height: 32px;

    border-radius: 4px;

    display: flex;
    justify-content: center;
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  &__logo-placeholder {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    flex-shrink: 0;

    background-color: var(--background-secondary);
    color: var(--text-color-secondary);
  }
}
</style>
