import { createI18n } from 'vue-i18n'

import locales from '@/locales'

/**
 * Tries to guess the user's preferred locale based on the Browser's language
 * @returns Guessed locale
 */
const getBrowserLocale = (): string => {
  const browserLanguages = [
    navigator.language.split('-')[0],
    ...navigator.languages.map((lang) => lang.split('-')[0]),
  ]

  for (const lang of browserLanguages) {
    if (lang && locales.find(({ name }) => name === lang)) return lang
  }

  return 'en'
}

const i18n = createI18n({
  allowComposition: true,
  fallbackLocale: 'en',
  fallbackWarn: false,
  legacy: false,
  locale: getBrowserLocale(),
  messages: locales.reduce(
    (acc, { name, messages }) => Object.assign(acc, { [name]: messages }),
    {}
  ),
  missingWarn: false,
})

export default i18n
