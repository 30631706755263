import PbAnalytics from './PbAnalytics'

const pbAnalytics = PbAnalytics.getInstance()

pbAnalytics.configure()

let prevPage: string | undefined

const analytics = {
  /**
   * Identify User
   * @param userId Id Of User
   */
  identify: (userId: string): void => pbAnalytics.identify(userId),

  /**
   * Abstracts `pbAnalytics.track` to manage page loads.
   * Dedupes page load calls
   * @param pageName Name of page being loaded
   */
  loadPage: (pageName: string): void => {
    if (prevPage !== pageName) {
      prevPage = pageName

      pbAnalytics.track('Page Load', {
        pageName,
      })
    }
  },

  /**
   * Reset user id (logout)
   */
  reset: () => pbAnalytics.reset(),

  /**
   * Sets specific data about user
   * @param data Data to set
   * @param override Option to override data if already exists
   */
  setUserData: (data: Record<string, unknown>, override: boolean) =>
    pbAnalytics.setUserData(data, override),

  /**
   * Track an analytics event
   * @param eventName Name of event to track
   * @param [args] Extra Args to pass
   */
  track: (eventName: string, args: Record<string, unknown>) =>
    pbAnalytics.track(eventName, args),
}

export default analytics
