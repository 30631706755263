import { type RouteRecordRaw } from 'vue-router'

export const brandReportRoutes: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () => import('../views/ReportsMainView.vue'),
        meta: {
          isLocationPage: true,
        },
        name: `brand.reports.main`,
        path: '',
        props: {
          scope: 'brand',
        },
      },
      {
        component: async () => import('../views/ReportsDetailView.vue'),
        meta: {
          isLocationPage: true,
        },
        name: `brand.reports.detail`,
        path: ':reportSlug',
        props: {
          scope: 'brand',
        },
      },
      {
        path: '**',
        redirect: { name: `brand.reports.main` },
      },
    ].filter((route) => !!route),
    component: {
      name: 'ReportsRouterView',
      template: '<router-view></router-view>',
    },
    meta: {
      isLocationPage: true,
      title: 'reports.reports',
    },
    name: `brand.reports`,
    path: 'reports',
    props: { scope: 'brand' },
    redirect: { name: `brand.reports.main` },
  },
]

export const locationReportRoutes: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () => import('../views/ReportsMainView.vue'),
        meta: {
          isLocationPage: true,
        },
        name: `location.reports.main`,
        path: '',
        props: {
          scope: 'location',
        },
      },
      {
        component: async () => import('../views/ReportsDetailView.vue'),
        meta: {
          isLocationPage: true,
        },
        name: `location.reports.detail`,
        path: ':reportSlug',
        props: {
          scope: 'location',
        },
      },
      {
        path: '**',
        redirect: { name: `location.reports.main` },
      },
    ].filter((route) => !!route),
    component: {
      name: 'ReportsRouterView',
      template: '<router-view></router-view>',
    },
    meta: {
      isLocationPage: true,
      title: 'reports.reports',
    },
    name: `location.reports`,
    path: 'reports',
    props: { scope: 'location' },
    redirect: { name: `location.reports.main` },
  },
]
