<i18n locale="en">
{
  "pages": {
    "invoices": "Invoices",
    "subscriptions": "Subscriptions",
    "payouts": "Payouts"
  }
}
</i18n>

<template>
  <DropdownNav :nav-items="navItems" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import DropdownNav, {
  type DropdownNavItem,
} from '@/components/Navigation/DropdownNav.vue'
import { useFeatureFlags } from '@/utils'

const { t } = useI18n()
const FeatureFlags = useFeatureFlags()

const navItems = computed<DropdownNavItem[]>(() => [
  {
    label: t('pages.invoices'),
    to: { name: 'brand.billing.invoices' },
  },
  {
    label: t('pages.subscriptions'),
    to: { name: 'brand.billing.methods' },
  },
  FeatureFlags.isEnabled('hermes.payouts-v2')
    ? {
        label: t('pages.payouts'),
        to: { name: 'brand.billing.payouts' },
      }
    : {
        label: t('pages.payouts'),
        to: { name: 'brand.payouts' },
      },
])
</script>
