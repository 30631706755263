<template>
  <button
    :class="[
      'copyable flex-row gap-2 align-center justify-between',
      border && 'copyable--border',
    ]"
    :shrink="iconOnly"
    @click.stop="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <OwnType
      v-if="!iconOnly"
      class="text--truncate"
      :text="label ?? value"
      :variant="variant"
      :value="label ?? value"
      color="inherit"
    />
    <template v-if="showIcon">
      <slot name="icon">
        <PhClipboardText class="flex-shrink-0" size="16" />
      </slot>
    </template>
  </button>
</template>

<script lang="ts" setup>
import { PhClipboardText } from '@phosphor-icons/vue'
import { useClipboard } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { FontType, OwnType } from '@/ui'
import { useNotify } from '@/utils'

const props = withDefaults(
  defineProps<{
    border?: boolean
    iconBehavior?: 'show' | 'hover'
    iconOnly?: boolean
    label?: string
    successMessage?: string
    variant?: FontType
    value: string
  }>(),
  {
    border: true,
    iconBehavior: 'show',
    iconOnly: false,
    label: undefined,
    successMessage: undefined,
    variant: 'monospace',
  }
)

const isHovering = ref(false)

const notify = useNotify()
const { t } = useI18n()
const { copy } = useClipboard()

const onClick = async () => {
  await copy(props.value)
  notify(props.successMessage ?? t('copy.success'), 'success')
}

const onMouseEnter = () => {
  isHovering.value = true
}
const onMouseLeave = () => {
  isHovering.value = false
}

const showIcon = computed(() => {
  if (props.iconBehavior === 'show') return true

  return isHovering.value
})
</script>

<style lang="scss" scoped>
@use '@/styles/globals';

.copyable {
  padding: 4px;
  color: var(--text-color-secondary);
  transition: transitionOne(border);

  &--border {
    border: 1px dashed var(--background-divider);
    border-radius: 4px;

    &:hover {
      border: 1px solid var(--background-divider);
    }
  }

  &:active {
    color: rgba(globals.$text-color-secondary, 60%);
  }
}
</style>
