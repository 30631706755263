<i18n locale="en">
{
  "title": "Add Brand",
  "actions": {
    "add": "Add Brand"
  },
  "labels": {
    "empty": "No Brands Found"
  }
}
</i18n>

<template>
  <OwnDialog :model-value="true" @cancel="emit('close')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="emit('close')" />
    </template>

    <div class="brand-selector-dialog__filter-bar">
      <SearchInput
        ref="searchInput"
        v-model="searchQuery"
        class="w-full"
        borderless
      />
    </div>

    <OwnDialogContent scroll>
      <OwnRadioGroup
        v-if="loadedOptions.length > 0"
        :model-value="selectedBrandId"
        :options="displayedOptions"
        @update:model-value="onBrandSelect"
      >
        <template #text-lhs="{ item }">
          <BrandLogo :brand="item" />
        </template>
      </OwnRadioGroup>

      <DefaultEmptyState v-else :message="t('labels.empty')">
        <template #icon>
          <PhBookBookmark size="24" />
        </template>
      </DefaultEmptyState>
    </OwnDialogContent>

    <OwnDialogFooter>
      <OwnButton :text="t('actions.add')" primary @click="onConfirmSelection">
        <template #icon-right>
          <PhCheckCircle size="24" />
        </template>
      </OwnButton>
    </OwnDialogFooter>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { PhBookBookmark, PhCheckCircle } from '@phosphor-icons/vue'
import {
  type ComponentPublicInstance,
  computed,
  onMounted,
  ref,
  watch,
} from 'vue'
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import { DefaultEmptyState } from '@/components/empty'
import { SearchInput } from '@/components/inputs'
import { logError } from '@/logger'
import {
  OwnButton,
  OwnDialog,
  OwnDialogHeading,
  OwnDialogContent,
  OwnDialogFooter,
  OwnRadioGroup,
  Option,
} from '@/ui'

import { type Brand } from '../../types'
import BrandLogo from '../BrandLogo.vue'

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'select', value: Brand): void
}>()

const { t } = useI18n()

const searchQuery = ref<string | undefined>(undefined)

const isLoading = ref(true)
const loadedOptions = ref<Brand[]>([])

const selectedBrand = ref<Brand>()

const searchInput = ref<ComponentPublicInstance | null>(null)

const selectedBrandId = computed(() => selectedBrand.value?.id)

const displayedOptions = computed<Array<Brand & Option<string>>>(() =>
  loadedOptions.value.map((brand) => ({
    ...brand,
    label: brand.name,
    value: brand.id,
  }))
)

const onBrandSelect = (brandId: string) => {
  const selectedBrandOption = loadedOptions.value.find(
    (brand) => brand.id === brandId
  )

  if (selectedBrandOption) {
    selectedBrand.value = selectedBrandOption
  }
}

const onConfirmSelection = () => {
  // User hasn't selected anything, just dismiss the dialog
  if (!selectedBrand.value) return emit('close')

  emit('select', selectedBrand.value)
}

const fetchBrands = async () => {
  try {
    const { results } = await ConfiguredClient.brands.getBrands({
      query: {
        highestSubscriptionTier: 'standard',
        query: searchQuery.value,
      },
    })

    loadedOptions.value = results.map((brand) => ({
      ...brand,
      label: brand.name,
      value: brand.id,
    }))
  } catch (err) {
    logError(err)
  } finally {
    isLoading.value = false
  }
}

watch(searchQuery, async () => {
  await fetchBrands()
})

onMounted(async () => {
  isLoading.value = true
  await fetchBrands()
})
</script>

<style lang="scss" scoped>
.brand-selector-dialog {
  &__filter-bar {
    padding: 16px 24px;
    border-bottom: 1px dashed var(--background-divider);
  }
}
</style>
