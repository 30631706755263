<i18n locale="en">
{
  "title": "Confirm",
  "message": "Would you really like to take this action?",
  "actions": {
    "confirm": "Confirm",
    "cancel": "Cancel"
  }
}
</i18n>

<template>
  <OwnDialog v-model="isVisible" @cancel="closeDialog">
    <template #heading>
      <OwnDialogHeading :title="title ?? t('title')" @cancel="closeDialog" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <slot>
          <OwnType :text="message ?? t('message')" />
        </slot>
      </OwnDialogContent>
    </template>
    <template #footer>
      <OwnDialogFooter>
        <OwnButton :text="t('actions.cancel')" @click="closeDialog" />
        <OwnButton
          :text="confirmLabel ?? t('actions.confirm')"
          primary
          @click="onConfirm"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnType,
} from '@/ui'

defineProps<{
  confirmLabel?: string
  message?: string
  title?: string
}>()
const emit = defineEmits<{
  (event: 'confirm'): void
  (event: 'cancel'): void
}>()

const { t } = useI18n()

const isVisible = defineModel<boolean>({ required: true })

const closeDialog = () => {
  emit('cancel')
  isVisible.value = false
}

const onConfirm = () => {
  emit('confirm')
  closeDialog()
}
</script>
