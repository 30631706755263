<i18n locale="en">
{
  "label": "Search",
  "placeholders": {
    "search": "Search"
  }
}
</i18n>

<template>
  <OwnInputContainer :label="t('label')" sr-only>
    <OwnInput
      ref="searchInput"
      v-model="searchTerm"
      class="search-input"
      :placeholder="placeholder ?? t('placeholders.search')"
      :borderless="borderless"
      font="subtitle"
      small
    >
      <template #prepend>
        <PhMagnifyingGlass size="24" />
      </template>
    </OwnInput>
  </OwnInputContainer>
</template>

<script lang="ts" setup>
import { PhMagnifyingGlass } from '@phosphor-icons/vue'
import { watchDebounced } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { focusInput, OwnInput, OwnInputContainer } from '@/ui'

const { t } = useI18n()

withDefaults(
  defineProps<{
    borderless?: boolean
    placeholder?: string
    standard?: boolean
  }>(),
  {
    borderless: false,
    placeholder: undefined,
    standard: false,
  }
)

const searchTerm = ref('')
const searchInput = ref<InstanceType<typeof OwnInput> | null>(null)

const modelVal = defineModel<string>({ default: '' })

const focus = () => {
  if (searchInput.value) {
    focusInput(searchInput.value)
  }
}

watchDebounced(
  searchTerm,
  () => {
    modelVal.value = searchTerm.value
  },
  {
    debounce: 300,
  }
)

defineExpose({
  focus,
})
</script>

<style lang="scss" scoped>
.search-input {
  background-color: var(--white);
}
</style>
