<template>
  <textarea
    :id="accessibilityId"
    v-model="modelVal"
    class="own-textarea text-paragraph"
    :class="[error && 'own-textarea--error', isEmpty && 'own-textarea--empty']"
    :name="accessibilityId"
    :rows="rows"
    :disabled="disabled"
    :placeholder="placeholder"
  ></textarea>
</template>

<script lang="ts" setup>
import { isDefined } from '@vueuse/core'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    accessibilityId?: string
    disabled?: boolean
    emptyAsNull?: boolean
    error?: boolean
    placeholder?: string
    rows?: string
  }>(),
  {
    accessibilityId: undefined,
    disabled: false,
    emptyAsNull: false,
    error: false,
    placeholder: undefined,
    rows: '5',
  }
)

const modelVal = defineModel<string>({
  set(newVal) {
    if (props.emptyAsNull && newVal === '') {
      return null
    }
    return newVal
  },
})
defineEmits<{ 'update:modelValue': [value: string | undefined | null] }>()

const isEmpty = computed(() => {
  return !isDefined(modelVal.value) || modelVal.value === ''
})
</script>

<style lang="scss">
@use '@/styles/globals';

.own-textarea {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--background-divider);

  background-color: var(--background-primary);
  color: var(--text-color-placeholder);

  @include globals.control-shadow();

  transition:
    border 0.15s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    color 0.15s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    box-shadow 0.15s cubic-bezier(0.68, -0.04, 0.26, 0.87);

  &::placeholder {
    color: var(--text-color-placeholder);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--text-color-placeholder);

    &::placeholder {
      color: var(--text-color-placeholder);
    }
  }

  &--error {
    border-color: var(--status-danger) !important;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--background-highlight);
    border-color: rgba(globals.$misc-brand, 0.05);
  }
}
</style>
