import get from 'lodash/get'

import { convertToPathObject } from '@/utils/structure'

import { buildBaseGetters } from '../../utils/getterFactory'

export const getterList = [
  'name',
  'slug',
  'timezone',
  'address/*',
  'analytics/*',
  'contact/*',
  'features/*',
  'features/automations/*',
  'funnel/*',
  'funnel/links/*',
  'hours/*',
  'hours/pickup/*',
  'hours/delivery/*',
  'integrations/*',
  'messaging/*',
  'orderTypes/*',
  'payments/*',
  'pos/*',
  'preparation/*',
  'processing/*',
  'subscription/*',
  'theme/*',
  'throttle/*',
  'listingsData/*',
]

const active = {
  '*': (state, getters) => {
    const locations = getters['brand/active/locations']

    // If single location, that location is always active
    if (locations?.length === 1) {
      return locations[0]
    } else {
      return getters['location/byId'](state.activeLocation)
    }
  },
  brand: {
    '*': (state, getters) => {
      const activeLocation = getters['location/active/*']

      return activeLocation
        ? getters['brand/byId'](activeLocation.brandId)
        : undefined
    },
    id: (state, getters) => {
      const targetLocation = getters['location/active/*']

      return targetLocation ? targetLocation.brandId : undefined
    },
  },
  id: (state, getters) => {
    return getters['location/active/*']?.id
  },
  ...buildBaseGetters(getterList, { active: true, scope: 'location' }),
}

const locationGetters = {
  location: {
    active,
    brand: {
      '*': (_state, getters) => (locationId) => {
        const targetLocation = getters['location/byId'](locationId)

        return targetLocation
          ? getters['brand/byId'](targetLocation.brandId)
          : undefined
      },
      id: (_state, getters) => (locationId) => {
        const targetLocation = getters['location/byId'](locationId)

        return targetLocation ? targetLocation.brandId : undefined
      },
    },
    byId: (state) => (locationId) => {
      return state.locations.find((location) => location.id === locationId)
    },
    dotPath: (_state, getters) => (locationId, dotPath) => {
      const location = getters['location/byId'](locationId)

      return location ? get(location, dotPath) : undefined
    },
    ...buildBaseGetters(
      getterList.filter((getter) => getter !== 'id'),
      { active: false, scope: 'location' }
    ),
  },
  locations: {
    '*': (state) => state.locations,
    byBrandId: (state) => (brandId) =>
      state.locations.filter((location) => location.brandId === brandId),
    ids: (state) => state.locations.map((location) => location.id),
  },
}

export default convertToPathObject(locationGetters)
