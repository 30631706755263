import isEqual from 'lodash/isEqual'

import removeObserver from './removeObserver'

/**
 * Deep comparison of two variables.
 * @description Runs `removeObserver` on inputs before comparing with Lodash's `isEqual`.
 * @param variable1 first variable
 * @param variable2 second variable
 * @returns true for same, false for different
 */
const deepEqual = (variable1: unknown, variable2: unknown): boolean => {
  variable1 = removeObserver(variable1)
  variable2 = removeObserver(variable2)
  return isEqual(variable1, variable2)
}

export default deepEqual
