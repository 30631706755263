import {
  type PayoutStatus,
  type PayoutTransactionSubjectType,
  type PayoutTransactionType,
  type PayoutType,
} from '@/features/billing/types'

export interface BillingEn {
  payout: {
    type: Record<PayoutType, string>
    status: Record<PayoutStatus, string>
    transaction: {
      type: Record<PayoutTransactionType, string>
      subject: Record<PayoutTransactionSubjectType, string>
    }
  }
}

export const billingEn: BillingEn = {
  payout: {
    status: {
      canceled: 'Canceled',
      failed: 'Failed',
      in_transit: 'In Transit',
      paid: 'Paid',
      pending: 'Pending',
    },
    transaction: {
      subject: {
        external: 'External',
        'gift-card': 'Gift Card',
        order: 'order',
      },
      type: {
        adjustment: 'Adjustment',
        'application-fee-refund': 'Application Fee Refund',
        charge: 'Charge',
        'dispute-fee': 'Dispute Fee',
        refund: 'Refund',
      },
    },
    type: {
      automatic: 'Automatic',
      instant: 'Instant',
    },
  },
}
