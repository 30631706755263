import { FullStory, isInitialized } from '@fullstory/browser'
import * as Sentry from '@sentry/vue'

import store from '@/store'

/**
 * Log to Sentry.
 * @param scope Log level.
 * @param data Data to log
 */
const logSentry = (
  scope: 'fatal' | 'error' | 'warning' | 'log' | 'info',
  data: unknown
): void => {
  Sentry.withScope((sentryScope) => {
    sentryScope.setLevel(scope)

    sentryScope.setTags({
      brandId: store.getters['core/brand/active/id'],
      brandName: store.getters['core/brand/active/name'],
      locationId: store.getters['core/location/active/id'],
      locationName: store.getters['core/location/active/name'],
      session: isInitialized()
        ? FullStory('getSession', { format: 'url.now' })
        : undefined,
      userId: store.getters['session/user']?.id,
      userRole: store.getters['session/user']?.role,
    })

    if (data instanceof Error) {
      Sentry.captureException(data)
    } else if (typeof data === 'string') {
      Sentry.captureMessage(data)
    } else {
      Sentry.captureMessage(
        data?.toString() ?? 'Failed to extract message from logged data'
      )
    }
  })
}

/**
 * Log at error level
 * @param data Data to log
 */
export const logError = (data: unknown): void => {
  const VITE_LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL

  if (VITE_LOG_LEVEL.includes('error')) {
    switch (import.meta.env.MODE) {
      case 'development':
        // eslint-disable-next-line no-console
        console.error(data)
        break

      default:
        logSentry('error', data)
        break
    }
  }
}

/**
 * Log at fatal level
 * @param data Data to log
 */
export const logFatal = (data: unknown): void => {
  const VITE_LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL

  if (VITE_LOG_LEVEL.includes('fatal')) {
    switch (import.meta.env.MODE) {
      case 'development':
        // eslint-disable-next-line no-console
        console.error(data)
        break

      default:
        logSentry('fatal', data)
        break
    }
  }
}

/**
 * Log at info level
 * @param data Data to log
 */
export const logInfo = (data: unknown): void => {
  const VITE_LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL

  if (VITE_LOG_LEVEL.includes('info')) {
    switch (import.meta.env.MODE) {
      case 'development':
        // eslint-disable-next-line no-console
        console.log(data)
        break

      default:
        logSentry('info', data)
        break
    }
  }
}

/**
 * Log at warning level
 * @param data Data to log
 */
export const logWarn = (data: unknown): void => {
  const VITE_LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL

  if (VITE_LOG_LEVEL.includes('warn')) {
    switch (import.meta.env.MODE) {
      case 'development':
        // eslint-disable-next-line no-console
        console.warn(data)
        break

      default:
        logSentry('warning', data)
        break
    }
  }
}
