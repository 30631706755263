type ValidRecord = Record<string, string | number | null | undefined>
type ValidOutputData = string | ValidRecord | null
type ValidInputData = ValidOutputData | undefined

export const emptyToNull = (dataToConvert: ValidInputData): ValidOutputData => {
  if (dataToConvert === null || dataToConvert === undefined) return null

  if (typeof dataToConvert === 'string') {
    if (dataToConvert === '') return null
  }

  if (typeof dataToConvert === 'object') {
    const convertedData: ValidRecord = {}

    for (const key in dataToConvert) {
      const value = dataToConvert[key]
      convertedData[key] = value === '' || value === undefined ? null : value
    }

    return convertedData
  }

  return dataToConvert
}
