import { type RouteRecordRaw } from 'vue-router'

export const billingRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () => import('../views/BillingPayoutsView.vue'),
        meta: {
          title: 'billing',
        },
        name: 'brand.billing.payouts',
        path: 'payouts',
      },
      {
        component: async () => import('../views/BillingPayoutsDetailView.vue'),
        meta: {
          title: 'billing',
        },
        name: 'brand.billing.payouts.detail',
        path: 'payouts/:payoutId',
        props: true,
      },
      {
        component: async () => import('../views/BillingHomeView.vue'),
        meta: {
          title: 'billing',
        },
        name: 'brand.billing.invoices',
        path: 'invoices',
      },
      {
        component: async () => import('../views/BillingDetailView.vue'),
        meta: {
          title: 'billing',
        },
        name: 'brand.billing.invoices.detail',
        path: 'invoices/:invoiceId',
      },
      {
        component: async () => import('../views/BillingPaymentMethodView.vue'),
        meta: {
          title: 'billing',
        },
        name: 'brand.billing.methods',
        path: 'methods',
      },
    ],
    component: {
      name: 'BillingRouterView',
      template: '<router-view></router-view>',
    },
    name: 'brand.billing',
    path: 'billing',
    redirect: { name: `brand.billing.invoices` },
  },
]
