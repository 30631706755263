<template>
  <div class="own-card-heading flex-col gap-2">
    <div
      class="own-card-heading__h-main-row h-full flex-1 flex-row justify-between align-center"
    >
      <div
        class="own-card-heading__h-main-row h-full flex-1 flex-row align-center"
      >
        <span
          v-if="title"
          class="own-card-heading__title text-subtitle-1"
          v-text="title"
        ></span>
      </div>

      <div
        v-if="$slots['center-action']"
        class="own-card-heading__h-main-row h-full flex-1 flex-row justify-center align-end"
      >
        <slot class="flex-1" name="center-action" />
      </div>

      <div
        v-if="$slots['right-action'] || to || $slots['center-action']"
        class="own-card-heading__h-main-row own-card-heading__rhs h-full flex-1 flex-row gap-2 align-center justify-end"
      >
        <slot name="right-action" />
        <OwnButton v-if="to" :text="linkText" small @click="navigateTo">
          <template v-if="linkIcon" #icon-left>
            <Component :is="linkIcon" />
          </template>
        </OwnButton>
      </div>
    </div>
    <div
      v-if="$slots['accent-row']"
      class="own-card-heading__accent-row flex-row justify-center"
    >
      <slot name="accent-row" />
    </div>
  </div>
</template>

<script>
import { OwnButton } from '../../OwnButton'

export default {
  name: 'OwnCardHeading',
  components: {
    OwnButton,
  },
  props: {
    /**
     * Link Icon
     */
    linkIcon: { type: Object, default: undefined },

    /**
     * Link Text
     */
    linkText: { type: String, default: undefined },

    /**
     * Title
     */
    title: { type: String, default: undefined },

    /**
     * Link To Page
     */
    to: { type: Object, default: undefined },
  },
  methods: {
    navigateTo() {
      const { to } = this
      if (to) {
        this.$router.push(to)
      }
    },
  },
}
</script>

<style lang="scss">
.own-card-heading {
  background: var(--white);
  border-radius: 8px 8px 0 0;
  min-height: 56px;

  border-bottom: 1px solid var(--background-divider);

  &__h-main-row {
    height: 56px;
  }

  &__title {
    width: 100%;
    flex-shrink: 0;
    padding: 8px 16px;
    overflow-wrap: break-word;
  }

  &__accent-row {
    padding: 0 8px 8px 8px;
  }

  &__rhs {
    padding: 8px 16px;
  }
}
</style>
