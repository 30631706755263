<template>
  <button
    class="preview-icon-button"
    :class="[disabled && 'preview-icon-button__disabled']"
    :disabled="disabled"
  >
    <component :is="icon" size="24" />
  </button>
</template>

<script>
export default {
  name: 'PreviewIconButton',
  props: {
    disabled: { type: Boolean, default: false },
    icon: { type: Object, required: true },
  },
}
</script>

<style lang="scss" scoped>
@use '@/styles/globals';

.preview-icon-button {
  color: var(--text-color-secondary);
  padding: 12px;

  &:not(#{&}__disabled) {
    &:hover {
      color: var(--text-color-placeholder);
    }
  }

  &__disabled {
    color: rgba(globals.$text-color-secondary, 0.2);
    cursor: not-allowed;
  }
}
</style>
