<template>
  <div class="own-progress-bar">
    <div
      :class="[
        'own-progress-bar__track',
        squareWhenFilled && 'own-progress-bar__track--square',
        `own-progress-bar__track--${color}`,
      ]"
    ></div>
    <div class="own-progress-bar__fill-container">
      <div
        :class="[
          'own-progress-bar__fill',
          squareWhenFilled && 'own-progress-bar__fill--square-when-filled',
          percent === 100 &&
            squareWhenFilled &&
            'own-progress-bar__fill--square-when-filled-complete',
          `own-progress-bar__fill--${color}`,
        ]"
      ></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    percent: number
    color?: 'info' | 'success'
    squareWhenFilled?: boolean
  }>(),
  {
    color: 'info',
    squareWhenFilled: false,
  }
)

const percentComplete = computed(() => {
  return `${props.percent}%`
})
</script>
<style lang="scss">
@use '@/styles/globals';

$track-height: 8px;
$border-radius: 4px;

.own-progress-bar {
  width: 100%;
  position: relative;

  height: $track-height;

  &__track {
    width: 100%;
    height: $track-height;
    border-radius: $border-radius;
    position: absolute;
    border: 1px dashed rgba(0, 0, 0, 10%);

    &--square {
      border-radius: 0;
    }

    &--info {
      background-color: rgba(globals.$misc-brand, 10%);
    }

    &--success {
      background-color: rgba(globals.$status-success, 10%);
    }
  }

  &__fill-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__fill {
    width: v-bind(percentComplete);
    height: 100%;
    border-radius: $border-radius;
    border: 1px solid rgba(0, 0, 0, 10%);

    &--square-when-filled {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &-complete {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &--info {
      background: var(--misc-brand);
    }

    &--success {
      background: var(--status-success);
    }
  }
}
</style>
