<i18n locale="en">
{
  "labels": {
    "optional": "Optional"
  }
}
</i18n>

<template>
  <div class="own-input-label w-full flex-col gap-1">
    <div
      v-if="props.label"
      class="flex-row justify-between"
      :class="[!description && 'own-input-label__label-row--no-desc']"
    >
      <div class="flex-row justify-between align-center">
        <div class="flex-row justify-start gap-1">
          <OwnType
            v-if="props.label"
            :for="props.accessibilityId"
            :text="props.label"
            variant="subtitle"
            color="primary"
            el="label"
          />
        </div>

        <OwnType
          v-if="props.optional"
          :text="t('labels.optional')"
          variant="paragraph-small"
        />
      </div>
    </div>
    <OwnType
      v-if="props.description"
      :text="props.description"
      variant="paragraph-small"
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { OwnType } from '../../OwnType'

const props = withDefaults(
  defineProps<{
    accessibilityId?: string
    description?: string
    label?: string
    optional?: boolean
  }>(),
  {
    accessibilityId: undefined,
    description: undefined,
    label: undefined,
    optional: false,
  }
)

const { t } = useI18n()
</script>
<style lang="scss">
.own-input-label {
  &__info-icon {
    margin-left: 4px;
  }

  &__description {
    color: var(--text-color-secondary);
  }
}
</style>
