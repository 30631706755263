<template>
  <hr
    class="own-rule"
    :class="[
      `own-rule--${type}`,
      !vertical && 'own-rule--horizontal',
      vertical && 'own-rule--vertical',
    ]"
  />
</template>

<script>
export default {
  name: 'OwnRule',
  props: {
    /**
     * Type of rule (dashed or solid)
     */
    type: {
      type: String,
      default: 'solid',
      validator: (v) => ['dashed', 'solid'].includes(v),
    },

    /**
     * Is vertical
     */
    vertical: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
.own-rule {
  &--horizontal {
    height: 0;
    width: 100%;
    border: transparent;
    border-top: 1px solid var(--background-divider);
  }

  &--vertical {
    width: 0;
    border: none;
    border: transparent;
    border-left: 1px solid var(--background-divider);
  }

  &--dashed {
    border-style: dashed;
  }
}
</style>
