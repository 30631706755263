<template>
  <LeftFlyoutLayout :is-open="isOpen">
    <template #flyout-content>
      <div class="builder-page-content__editor-container h-full flex-col">
        <slot name="editor" />
      </div>
    </template>

    <template #default>
      <div class="builder-page-content__preview h-full">
        <slot name="preview" />
      </div>
    </template>
  </LeftFlyoutLayout>
</template>

<script>
import { LeftFlyoutLayout } from '@/components/layouts'

export default {
  name: 'BuilderPageContent',
  components: {
    LeftFlyoutLayout,
  },
  props: {
    /**
     * Control whether flyout is open from parent.
     */
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints';
@use '@/styles/globals';

.builder-page-content {
  &__editor-container {
    @include globals.flyout-shadow();
    z-index: 10;
  }

  &__editor-actions {
    padding: 16px;
    border-top: 1px solid var(--background-divider);
  }

  &__preview {
    background-color: var(--background-secondary);
    overflow-y: auto;
    z-index: 5;
    padding: 40px 16px;

    @include breakpoints.respond-to('md-and-up') {
      padding: 40px 48px;
    }
  }
}
</style>
