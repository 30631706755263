<template>
  <div class="left-sidebar-layout flex-row w-full h-full">
    <div v-if="$slots['left']" class="left-sidebar-layout__nav">
      <slot name="left" />
    </div>

    <slot />
  </div>
</template>

<style lang="scss" scoped>
.left-sidebar-layout {
  background-color: var(--background-primary);

  &__nav {
    width: 300px;
    flex-shrink: 0;

    background-color: var(--background-secondary);
    border-right: 1px dashed var(--background-divider);
  }
}
</style>
