<template>
  <OwnCard
    class="own-table-table-renderer"
    el="table"
    :border-color="border ? undefined : 'transparent'"
    :control="border"
  >
    <thead class="own-table-table-renderer__heading-container">
      <tr
        :class="[
          'own-table-table-renderer__heading',
          `flex-row gap-${options.row.spacing} align-center`,
        ]"
      >
        <span
          v-if="reorderable"
          class="own-table-table-renderer__reorder-heading-spacer"
        ></span>

        <th
          v-for="column of props.columns"
          :key="column.key"
          class="flex-1"
          :class="[`text--${column?.align ?? 'left'}`]"
          :style="{
            maxWidth: column.minWidth ? undefined : toPx(column.width),
            minWidth: toPx(column.minWidth ?? column.width),
          }"
        >
          <slot :name="`heading.${column.key}`">
            <OwnType
              v-if="!column?.hideLabel"
              class="w-full"
              el="span"
              :text="column?.label ?? '—'"
              :info="column?.info"
              variant="subtitle"
            />
          </slot>
        </th>
      </tr>
    </thead>

    <!--  Search returns no results  -->
    <div v-if="!hasItems" class="own-table-table-renderer__no-search-results">
      <template v-if="$slots['no-query-results']">
        <slot name="no-query-results" />
      </template>

      <template v-else-if="$slots['no-data']">
        <slot name="no-data" />
      </template>
    </div>

    <tbody
      v-else
      ref="reorderableList"
      class="own-table-table-renderer__body flex-col"
    >
      <!-- @vue-expect-error key should be part of item -->
      <tr
        v-for="(item, rowIdx) of items"
        :key="item[itemKey]"
        :class="[
          'own-table-table-renderer__row',
          hasClick && 'own-table-table-renderer__row--click',
          options.body.dividers && 'own-table-table-renderer__row--dividers',
          `flex-row gap-${options.row.spacing} align-center`,
        ]"
        @keyup.enter="emit('click', item)"
        @click="emit('click', item)"
      >
        <DragHandle v-if="props.reorderable" />

        <td
          v-for="(column, idx) of props.columns"
          :key="`${column.key}-${item[itemKey]}`"
          class="flex-1"
          :style="{
            maxWidth: column.minWidth ? undefined : toPx(column.width),
            minWidth: toPx(column.minWidth ?? column.width),
          }"
        >
          <!--  Override specific column (#item.name)  -->
          <slot
            :name="`item.${column.key}`"
            :item="item"
            :row-idx="rowIdx"
            :is-first="idx === 0"
          >
            <!--  Override all columns (#item)  -->
            <slot
              :column-key="column.key"
              name="item"
              :item="item"
              :is-first="idx === 0"
            >
              <!-- @vue-expect-error Need to fix the generic type used for this component -->
              <DefaultTableCell :item="item" :column="column" />
            </slot>
          </slot>
        </td>
      </tr>
    </tbody>
  </OwnCard>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import Sortable, { SortableEvent } from 'sortablejs'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import { ReorderEvent } from '@/utils/helpers/reorder'

import { OwnCard } from '../../OwnCard'
import { OwnType } from '../../OwnType'
import DragHandle from '../../reorderable/DragHandle.vue'
import { OwnTableColumnConfig, OwnTableVariants } from '../types'
import { getTableOptions } from '../utils/getTableOptions'

import DefaultTableCell from './DefaultTableCell.vue'

const props = withDefaults(
  defineProps<{
    border: boolean
    columns: OwnTableColumnConfig<T>[]
    hasClick: boolean
    items: T[]
    itemKey: string
    query?: string
    reorderable: boolean
    variant: OwnTableVariants
  }>(),
  {
    query: undefined,
  }
)

const emit = defineEmits<{
  (event: 'click', value: T): void
  (event: 'reorder', value: ReorderEvent): void
}>()

const reorderableList = ref<HTMLTableSectionElement | null>(null)

const options = getTableOptions(props.variant)

const hasItems = computed(() => {
  return props.items.length > 0
})

const toPx = (value: number | undefined) => {
  if (value === undefined) return undefined

  return `${value}px`
}

const rowRadius = computed(() => toPx(options.row.radius ?? 0))
const rowPadding = computed(() => toPx(options.row.padding ?? 16))
const bodyPadding = computed(() => toPx(options.body.padding ?? 0))

const mountSortable = () => {
  nextTick(() => {
    if (props.reorderable && reorderableList.value) {
      Sortable.create(reorderableList.value, {
        animation: 150,
        direction: 'vertical',
        handle: '.drag-handle',
        onEnd: ({ oldIndex, newIndex }: SortableEvent) => {
          if (oldIndex === newIndex) return
          emit('reorder', { newIndex, oldIndex } as ReorderEvent)
        },
      })
    }
  })
}

onMounted(mountSortable)

watch(hasItems, (newValue) => {
  if (newValue) mountSortable()
})
</script>

<style lang="scss">
.own-table-table-renderer {
  overflow: auto;

  &__heading-container {
    padding: 16px 16px 0 16px;
  }

  &__reorder-heading-spacer {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &__no-search-results {
    padding: 16px;
  }

  &__heading {
    padding-bottom: 16px;
    border-bottom: 1px dashed var(--background-divider);
  }

  &__body {
    padding: v-bind(bodyPadding);
  }

  &__row {
    border-radius: v-bind(rowRadius);
    padding: v-bind(rowPadding);

    &--click {
      cursor: pointer;

      &:hover {
        background-color: var(--background-secondary);
      }
    }

    &--dividers {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 16px;
        background: var(--background-divider);
        height: 1px;
        width: calc(100% - 32px);
      }
    }

    &:last-child {
      &:after {
        height: 0;
      }
    }
  }
}
</style>
