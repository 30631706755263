/**
 * Router Structure:
 *
 * /overview
 * /otc
 *  /overview
 *  /sms -> generated
 *    /new
 *    /:campaignId
 *  /emails -> generated
 *    /new
 *    /:campaignId
 * /automations -> Old automations view, will be replaced soon
 */

import { type RouteLocationNormalized } from 'vue-router'

import { emailBuilderRoutes } from '../builders/email/router/emailBuilderRouter'
import { textBuilderRoutes } from '../builders/text/router/textBuilderRouter'

export const marketingRouter = [
  {
    children: [
      {
        component: async () => import('../views/CampaignsOverviewView.vue'),
        meta: {
          title: 'marketing.overview',
        },
        name: 'brand.marketing.overview',
        path: 'overview',
      },
      {
        component: async () => import('../views/MarketingFlyersView.vue'),
        meta: {
          title: 'marketing.flyers',
        },
        name: 'brand.marketing.flyers',
        path: 'flyers',
      },
      {
        children: [
          {
            component: async () => import('../views/OtcTableView.vue'),
            meta: {
              title: 'marketing.otc',
            },
            name: 'brand.marketing.otc.overview',
            path: 'overview',
          },
          ...emailBuilderRoutes,
          ...textBuilderRoutes,
        ],
        component: {
          name: 'OtcRouterView',
          template: '<router-view></router-view>',
        },
        name: 'brand.marketing.otc',
        path: 'otc',
        redirect: { name: 'brand.marketing.otc.overview' },
      },
      {
        children: [
          {
            component: async () =>
              import('../views/AutomatedCampaignsDetailView.vue'),
            name: 'brand.marketing.automations.detail',
            path: ':campaign',
            props: (route: RouteLocationNormalized) => ({
              campaignId: route.params.campaign,
            }),
          },
        ],
        component: async () => import('../views/AutomatedCampaignsView.vue'),
        meta: {
          title: 'marketing.automations',
        },
        name: 'brand.marketing.automations',
        path: 'automations',
      },
    ],
    component: {
      name: 'MarketingRouterView',
      template: '<router-view></router-view>',
    },
    name: 'brand.marketing',
    path: 'marketing',
    redirect: { name: 'brand.marketing.overview' },
  },
]
