import { type Ref, ref } from 'vue'
import { useRoute, useRouter, type LocationQueryValue } from 'vue-router'

export const useRouterQueryParam = (
  key: string
): [
  Ref<LocationQueryValue | LocationQueryValue[] | undefined>,
  (newValue: string | undefined) => Promise<void>,
] => {
  const route = useRoute()
  const router = useRouter()

  const paramValue = ref<LocationQueryValue | LocationQueryValue[] | undefined>(
    route.query[key]
  )

  const updateParam = async (newValue: string | undefined): Promise<void> => {
    await router.push({
      ...route,
      query: {
        ...route.query,
        [key]: newValue,
      },
    })
  }

  return [paramValue, updateParam]
}
