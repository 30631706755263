<template>
  <div class="own-checkbox-group flex-col">
    <label
      v-for="item of items"
      :key="item.id"
      class="own-checkbox-group__row flex-row justify-between align-center gap-1"
      :class="[
        disabledItems.includes(item.id) && 'own-checkbox-group__row--disabled',
      ]"
      :for="deconflict(item.id)"
    >
      <span class="flex-row gap-2 align-center">
        <slot name="prefix" :item="item" />

        <OwnType
          :text="item.label"
          el="span"
          variant="subtitle"
          :color="disabledItems.includes(item.id) ? 'secondary' : 'primary'"
        />
      </span>

      <span class="flex-row gap-2 align-center">
        <OwnType
          v-if="item.description"
          :text="item.description"
          el="span"
          variant="paragraph-small"
          color="secondary"
        />

        <!-- Relies on unscoped (global) styles in `OwnCheckbox` via the class name `own-checkbox` -->
        <input
          :id="deconflict(item.id)"
          v-model="selectedValues"
          class="own-checkbox"
          :disabled="disabledItems.includes(item.id)"
          :value="item.id"
          type="checkbox"
          @input="onCheckboxInput(item.id)"
        />
      </span>
    </label>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="T extends Option<string> & { id: string; description?: string }"
>
import { generateToken } from '@/utils'

import { OwnType } from '../OwnType'
import { Option } from '../types'

withDefaults(
  defineProps<{
    disabledItems?: string[]

    /**
     * Items to render
     */
    items: T[]
  }>(),
  {
    disabledItems: () => [],
  }
)
const emit = defineEmits<{
  (event: 'select', value: string): void
  (event: 'deselect', value: string): void
}>()

const componentId = generateToken(8)
/**
 * Use a component instance-specific prefix to avoid conflicts with the `<input>` `id` attribute.
 * @param {string} id An identifier
 */
const deconflict = (id: string) => `${componentId}-${id}`

const selectedValues = defineModel<string[]>({ required: true })

const onCheckboxInput = (interactionId: string) => {
  if (selectedValues.value.includes(interactionId)) {
    emit('deselect', interactionId)
  } else {
    emit('select', interactionId)
  }
}
</script>

<style lang="scss">
.own-checkbox-group {
  &__row {
    padding: 12px 0;
    border-bottom: 1px dashed var(--background-divider);
    user-select: none;
    cursor: pointer;

    &:first-child {
      padding: 0 0 12px 0;
    }

    &:last-child {
      padding: 12px 0 0 0;
      border-bottom: none;
    }

    &:only-child {
      padding: 0;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
}
</style>
