<template>
  <OwnCard
    class="own-card-list"
    :class="[control && 'own-card-list--control']"
    :hide-border="!control"
    :control="control"
  >
    <template v-if="items.length && reorderable">
      <Reorderable
        v-slot="{ item, index }"
        v-model="items"
        item-key="_key"
        row-class="own-card-list__row"
        @reorder="onReorder"
      >
        <button
          v-if="clickable"
          class="flex-row gap-3 align-center w-full"
          @click="$emit('click', { index, item })"
        >
          <DragHandle v-if="reorderable && items.length > 1" />
          <slot name="item" :item="item" :index="index" />
        </button>
        <div v-else class="flex-row gap-3 align-center w-full">
          <DragHandle v-if="reorderable && items.length > 1" />
          <slot name="item" :item="item" :index="index" />
        </div>
      </Reorderable>
    </template>

    <template v-else-if="items.length">
      <template v-for="(item, index) in items" :key="item._key">
        <button
          v-if="clickable"
          class="own-card-list__row flex-row gap-3 align-center w-full"
          @click="$emit('click', { index, item })"
        >
          <slot name="item" :item="item" :index="index" />
        </button>
        <div
          v-else
          class="own-card-list__row flex-row gap-3 align-center w-full"
        >
          <slot name="item" :item="item" :index="index" />
        </div>
      </template>
    </template>

    <template v-else>
      <slot name="empty" />
    </template>
  </OwnCard>
</template>

<script>
import { reorder } from '@/utils/helpers/reorder'

import { OwnCard } from '../OwnCard'
import DragHandle from '../reorderable/DragHandle.vue'
import Reorderable from '../reorderable/Reorderable.vue'

export default {
  name: 'OwnCardList',
  components: {
    DragHandle,
    OwnCard,
    Reorderable,
  },
  inheritAttrs: false,
  props: {
    clickable: { type: Boolean, default: false },

    control: { type: Boolean, default: false },

    /**
     * Block key to be used for for-key.  When a string is provided,
     * the OwnCardList will search for item keys of that value.
     * An explicit null value can be used here to force updates for every
     * change events.  This is a heavy operation but allows arrays with no
     * unique values to be used.
     * @default id
     */
    itemKey: { type: [String, null], default: 'id' },

    /**
     * List of items
     * @model
     */
    modelValue: { type: Array, default: () => [] },

    reorderable: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'click'],
  computed: {
    items: {
      get() {
        const { itemKey } = this
        if (itemKey) {
          return this.modelValue.map((item) => ({
            ...item,
            _key: item[itemKey],
          }))
        }

        return this.modelValue.map((item) => ({
          ...item,
          _key: `${Date.now().toString()}-${Math.random()}`,
        }))
      },
      set(newVal) {
        this.$emit(
          'update:modelValue',
          newVal.map(({ _key, ...value }) => value)
        )
      },
    },
  },
  methods: {
    onReorder({ newIndex, oldIndex }) {
      this.items = reorder(this.items, oldIndex, newIndex)
    },
  },
}
</script>

<style lang="scss">
.own-card-list {
  &--control {
    padding: 16px;
  }

  &__row {
    background-color: var(--background-primary);
    padding: 12px 0;
    border-bottom: 1px dashed var(--background-divider);

    &:first-child {
      padding: 0 0 12px 0;
    }

    &:last-child {
      padding: 12px 0 0 0;
      border-bottom: none;
    }

    &:only-child {
      padding: 0;
    }
  }
}
</style>
