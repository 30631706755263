<template>
  <div class="dialog-footer flex-row justify-end gap-3">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.dialog-footer {
  padding: 24px;
  border-top: 1px dashed var(--background-divider);
}
</style>
