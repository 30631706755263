import { updateMutationFactory } from '../../utils/updateMutationFactory'

const updateLocationFactory = updateMutationFactory('locations')

export default {
  SET_ACTIVE_LOCATION: (state, locationId) => {
    state.activeLocation = locationId
  },
  SET_DEFAULTS: (state, defaultState) => {
    for (const key of Object.keys(defaultState)) {
      state[key] = defaultState[key]
    }
  },
  SET_LOCATION: (state, locationUpdate) => {
    if (state.locations.length === 0) {
      state.locations = [locationUpdate]
    } else {
      const targetIndex = state.locations.findIndex(
        (brand) => brand.id === locationUpdate.id
      )

      if (targetIndex === -1) {
        state.locations.splice(state.locations.length, 1, locationUpdate)
      } else {
        state.locations.splice(targetIndex, 1, {
          ...state.locations[targetIndex],
          ...locationUpdate,
        })
      }
    }
  },
  SET_LOCATIONS: (state, locations) => {
    state.locations = locations
  },
  UNLOAD_LOCATION: (state, locationId) => {
    state.locations = state.locations.filter(
      (location) => location.id !== locationId
    )
  },
  UPDATE_LOCATION_ADDRESS: updateLocationFactory('address'),
  UPDATE_LOCATION_ANALYTICS: updateLocationFactory('analytics'),
  UPDATE_LOCATION_AUTOMATIONS: updateLocationFactory('automations', [
    'features',
  ]),
  UPDATE_LOCATION_CONTACT: updateLocationFactory('contact'),
  UPDATE_LOCATION_FEATURES: updateLocationFactory('features'),
  UPDATE_LOCATION_FUNNEL: updateLocationFactory('funnel'),
  UPDATE_LOCATION_FUNNEL_LINKS: updateLocationFactory('links', ['funnel']),
  UPDATE_LOCATION_HOURS: updateLocationFactory('hours'),
  UPDATE_LOCATION_JOBS: updateLocationFactory('jobs'),
  UPDATE_LOCATION_LISTINGS_DATA: updateLocationFactory('listingsData'),
  UPDATE_LOCATION_LISTING_UPDATE_TIME:
    updateLocationFactory('listingsUpdatedAt'),
  UPDATE_LOCATION_MESSAGING: updateLocationFactory('messaging'),
  UPDATE_LOCATION_NAME: updateLocationFactory('name'),
  'UPDATE_LOCATION_ORDER-TYPES': updateLocationFactory('orderTypes'),
  UPDATE_LOCATION_PAYMENTS: updateLocationFactory('payments'),
  UPDATE_LOCATION_POS: updateLocationFactory('pos'),
  UPDATE_LOCATION_PREPARATION: updateLocationFactory('preparation'),
  UPDATE_LOCATION_PROCESSING: updateLocationFactory('processing'),
  UPDATE_LOCATION_SLUG: updateLocationFactory('slug'),
  UPDATE_LOCATION_THEME: updateLocationFactory('theme'),
  UPDATE_LOCATION_THROTTLE: updateLocationFactory('throttle'),
  UPDATE_LOCATION_TIMEZONE: updateLocationFactory('timezone'),
  UPDATE_LOCATION_WOFLOW: updateLocationFactory('woflow', ['integrations']),
}
