<template>
  <input
    :id="accessibilityId"
    class="own-checkbox"
    :class="['own-checkbox', rounded && 'own-checkbox--rounded']"
    :checked="modelVal"
    type="checkbox"
    :disabled="disabled"
    @click.stop="onClick"
  />
</template>
<script lang="ts" setup>
withDefaults(
  defineProps<{
    accessibilityId?: string
    disabled?: boolean
    rounded?: boolean
  }>(),
  {
    accessibilityId: undefined,
    disabled: false,
    rounded: false,
  }
)

const modelVal = defineModel<boolean>()

const onClick = () => {
  modelVal.value = !modelVal.value
}
</script>
<!-- Unscoped so `OwnCheckboxGroup` can use these styles too -->
<style lang="scss">
@use '@/styles/globals';
@use '../Common/scss/focus';

.own-checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  color: #000000;
  border: 1px solid var(--background-divider);
  border-radius: 8px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  flex-shrink: 0;

  &--rounded {
    border-radius: 50%;
  }

  &:disabled {
    background-color: var(--background-secondary);
    cursor: not-allowed;
  }

  &:not(#{&}:disabled) {
    @include globals.control-shadow();
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 8px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-color: var(--background-divider);
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(37deg);
    opacity: 0;
  }

  &:hover::before {
    border-color: var(--background-divider);
  }

  &:checked {
    color: var(--white);
    border-color: globals.$bluetiful-500;
    background: globals.$bluetiful-500;

    &::before {
      opacity: 1;
    }

    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  &:focus {
    @include focus.custom-focus-ring();
  }
}
</style>
