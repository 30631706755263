<template>
  <div class="own-mode-switch flex-row">
    <button
      v-for="option of options"
      :key="option.value"
      :class="[
        'own-mode-switch__button',
        modelVal === option.value && 'own-mode-switch__button--active',
        'flex-row gap-2 justify-center align-center',
      ]"
      @click="onClick(option.value)"
    >
      <component
        :is="option.icon"
        v-if="option.icon"
        class="flex-shrink-0"
        size="24"
      />

      <OwnType
        :text="option.label"
        el="span"
        variant="button"
        color="inherit"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { OwnType } from '../OwnType'

import { SwitchOption } from './types'

defineProps<{
  options: SwitchOption[]
}>()

const modelVal = defineModel<string>()

const onClick = (newValue: string) => {
  modelVal.value = newValue
}
</script>

<style lang="scss">
@use '@/styles/globals';

.own-mode-switch {
  height: 50px;
  padding: 4px;
  border: 1px solid var(--background-divider);
  border-radius: 8px;

  @include globals.control-shadow();

  &__button {
    border-radius: 4px;
    padding: 0 16px;

    background-color: var(--background-primary);
    color: var(--text-color-secondary);

    &--active {
      background-color: var(--background-secondary);
      color: var(--text-color-placeholder);
    }
  }
}
</style>
