<template>
  <button
    class="own-menu-row"
    :class="[`own-menu-row--${color}`]"
    @click="$emit('click', $event)"
  >
    <component
      :is="actionItem.icon"
      v-if="actionItem.icon"
      class="own-menu-row__icon"
    />
    <span class="text-subtitle-1" v-text="actionItem.label"></span>
  </button>
</template>

<script>
const ALLOWED_COLORS = ['danger']

export default {
  name: 'OwnMenuRow',
  props: {
    /**
     * Single action item
     */
    actionItem: { type: Object, required: true },
  },
  emits: ['click'],
  computed: {
    color() {
      const { actionItem } = this

      if (actionItem.color && ALLOWED_COLORS.includes(actionItem.color)) {
        return actionItem.color
      } else {
        return 'default'
      }
    },
  },
}
</script>

<style lang="scss">
@use '@/styles/globals';

.own-menu-row {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 2px;
  text-align: left;

  &__icon {
    font-size: 22px;
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--background-secondary);
  }

  &--default {
    color: var(--text-color-secondary);
  }

  &--danger {
    color: var(--status-danger);
  }
}
</style>
