<i18n locale="en">
{
  "labels": {
    "select-location": "Select your location"
  }
}
</i18n>

<template>
  <div class="page-blocker-content flex-col align-center justify-start">
    <div class="flex-col justify-center align-center gap-6">
      <OwnType
        :text="t('labels.select-location')"
        variant="heading"
        el="span"
      />

      <ScopeSelectorLocation />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { OwnType } from '@/ui'

import ScopeSelectorLocation from '../nav/components/ScopeSelectorLocation.vue'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints';
@use '@/styles/globals';

.page-blocker-content {
  height: 100%;
  background-color: rgba(globals.$background-secondary, 0.88);
  border: 1px solid var(--background-divider);
  border-radius: 12px;
  padding: 16px;

  @include breakpoints.respond-to('md-and-up') {
    padding: 64px;
  }

  & > div {
    max-width: 450px;
    width: 100%;
  }
}
</style>
