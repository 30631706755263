<template>
  <button
    :class="[
      'own-button',
      isDefault && 'own-button--default',
      danger && 'own-button--danger',
      disabled && 'own-button--disabled',
      clamp && 'own-button--clamp',
      primary && 'own-button--primary',
      tertiary && 'own-button--tertiary',
      small && 'own-button--small',
      active && 'own-button--active',
      !text && 'own-button--icon-only',
    ]"
    :disabled="disabled"
    :type="type"
    @click.stop="onClick"
  >
    <template v-if="processing">
      <PhCircleNotch class="own-button__icon-loading" size="21" />
    </template>

    <template v-else>
      <span v-if="hasIconLeft" class="own-button__icon">
        <slot name="icon-left" />
      </span>
      <span
        v-if="text"
        :class="[
          fontSize,
          textTruncate && 'text--truncate',
          hasIconLeft && 'own-button__text--icon-left',
          hasIconRight && 'own-button__text--icon-right',
        ]"
        v-text="text"
      ></span>
      <span v-if="hasIconRight" class="own-button__icon">
        <slot name="icon-right" />
      </span>
    </template>
  </button>
</template>

<script lang="ts" setup>
import { PhCircleNotch } from '@phosphor-icons/vue'
import { computed, useSlots } from 'vue'

const props = withDefaults(
  defineProps<{
    active?: boolean
    clamp?: boolean
    danger?: boolean
    disabled?: boolean
    primary?: boolean
    processing?: boolean
    small?: boolean
    tertiary?: boolean
    text?: string
    textTruncate?: boolean
    type?: 'button' | 'submit' | 'reset'
  }>(),
  {
    active: false,
    clamp: false,
    danger: false,
    disabled: false,
    primary: false,
    processing: false,
    small: false,
    tertiary: false,
    text: undefined,
    textTruncate: false,
    type: 'button',
  }
)

const emit = defineEmits<{
  click: [data: MouseEvent]
}>()

const slots = useSlots()

const fontSize = computed(() => {
  return props.small ? 'text-button' : 'text-button-large'
})

const hasIconLeft = computed(() => {
  return !!slots['icon-left']
})

const hasIconRight = computed(() => {
  return !!slots['icon-right']
})

const isDefault = computed(() => {
  return !props.primary && !props.danger
})

const onClick = (event: MouseEvent) => {
  if (!props.processing) {
    emit('click', event)
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/globals';
@use '../Common/scss/focus';

.own-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  flex-shrink: 0;

  @include globals.control-shadow();

  padding: 12px 16px;

  border-radius: 8px;

  transition:
    background-color 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    color 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87);

  &--clamp {
    align-self: flex-start;
  }

  &--small {
    padding: 4px 0;
    border-radius: 0;
  }

  &--default {
    color: var(--text-color-secondary);
    background-color: var(--background-secondary);
    border-color: var(--background-divider);
    box-shadow: 0 1px 2px rgba(globals.$slate-900, 0.16);

    &.own-button--small {
      background-color: transparent;
      border-color: transparent;
      color: var(--text-color-secondary);
      box-shadow: none;
    }

    &:hover {
      color: var(--text-color-secondary);
      background-color: var(--background-divider);
      border-color: var(--background-divider);

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: globals.$slate-700;
      }
    }

    &:active,
    &.own-button--active {
      outline: none;
      color: var(--text-color-secondary);
      background-color: var(--background-primary);
      border-color: var(--background-divider);

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: globals.$slate-700;
        border-bottom-color: globals.$slate-700;
      }
    }

    &:focus {
      outline: none;
      color: var(--text-color-placeholder);
      background-color: var(--background-primary);
      border-color: var(--background-divider);
      box-shadow: 0 0 0 4px globals.$bluetiful-300;

      &.own-button--small {
        background-color: globals.$slate-200;
        border-color: transparent;
        color: globals.$slate-700;
        border-bottom-color: globals.$slate-700;
        box-shadow: 0 0 0 4px globals.$slate-200;
      }
    }

    &.own-button--disabled {
      background-color: globals.$slate-300;
      color: var(--text-color-placeholder);
      box-shadow: none;

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: var(--text-color-secondary);
      }
    }
  }

  &--primary {
    color: var(--white);
    background-color: globals.$bluetiful-500;
    border-color: globals.$bluetiful-700;
    box-shadow: 0 1px 2px rgba(globals.$slate-900, 0.16);

    &.own-button--small {
      color: globals.$bluetiful-500;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    &:hover {
      background-color: globals.$bluetiful-600;
      border-color: globals.$bluetiful-800;

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: globals.$bluetiful-700;
      }
    }

    &:active,
    &.own-button--active {
      outline: none;
      background-color: globals.$bluetiful-400;
      border-color: globals.$bluetiful-600;

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: globals.$bluetiful-700;
        border-bottom-color: globals.$bluetiful-700;
      }
    }

    &:focus {
      outline: none;
      background-color: globals.$bluetiful-500;
      border-color: globals.$bluetiful-700;
      box-shadow: 0 0 0 4px globals.$bluetiful-300;

      &.own-button--small {
        background-color: globals.$bluetiful-300;
        border-color: transparent;
        color: globals.$bluetiful-700;
        border-bottom-color: globals.$bluetiful-700;
      }
    }

    &.own-button--disabled {
      background-color: globals.$slate-300;
      color: var(--text-color-placeholder);
      border-color: transparent;
      box-shadow: none;

      &.own-button--small {
        background-color: transparent;
        color: var(--text-color-secondary);
        border-color: transparent;
      }
    }
  }

  &--tertiary {
    color: var(--text-color-secondary);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      color: var(--text-color-secondary);
      background-color: transparent;
      border-color: transparent;
    }

    &:active,
    &.own-button--active {
      color: var(--text-color-secondary);
      background-color: transparent;
      border-color: transparent;
    }

    &:focus {
      outline: none;
      color: var(--text-color-placeholder);
      background-color: var(--background-primary);
      border-color: var(--background-secondary);
      box-shadow: 0 0 0 4px globals.$bluetiful-300;
    }

    &.own-button--disabled {
      background-color: transparent;
      color: var(--text-color-placeholder);
      box-shadow: none;
    }
  }

  &--danger {
    color: var(--status-danger);
    background-color: var(--background-secondary);
    border-color: var(--background-divider);

    &.own-button--small {
      color: var(--status-danger);
      background-color: transparent;
      border-color: transparent;
    }

    &:hover {
      background-color: var(--background-divider);
      border-color: var(--background-divider);

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: var(--status-danger);
      }
    }

    &:active,
    &.own-button--active {
      outline: none;
      background-color: var(--background-secondary);
      border-color: var(--background-divider);

      &.own-button--small {
        background-color: transparent;
        border-color: transparent;
        color: var(--status-danger);
        border-bottom-color: var(--status-danger);
      }
    }

    &:focus {
      outline: none;
      background-color: var(--background-secondary);
      border-color: var(--background-divider);
      box-shadow:
        0 1px 2px 0 rgba(0, 0, 0, 0.15),
        0 0 0 2px rgba(var(--status-danger), 0.2);

      &.own-button--small {
        border-color: transparent;
        color: var(--status-danger);
        border-bottom-color: var(--status-danger);
      }
    }

    &.own-button--disabled {
      color: var(--status-danger);
      border-color: transparent;
      box-shadow: none;

      &.own-button--small {
        background-color: transparent;
        color: var(--status-danger);
        border-color: transparent;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    &.own-button--small {
      opacity: 0.48;
    }
  }

  &__icon {
    flex-shrink: 0;
  }

  &--icon-only {
    padding: 12px;
  }

  &__text {
    &--icon-left {
      margin-left: 8px;
    }

    &--icon-right {
      margin-right: 8px;
    }
  }

  &__icon-loading {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
