import { adminRouter } from '@/features/admin/router/admin.router'
import { authRouter } from '@/features/auth/router/auth.router'
import { oauthRouter } from '@/features/oauth/router/oauth.router'

import { brandRoutes } from './brandRoutes'

export default [
  {
    children: [...brandRoutes, ...adminRouter, ...oauthRouter, ...authRouter],
    component: {
      name: 'AppRouterView',
      template: '<router-view></router-view>',
    },
    path: '/',
  },
  {
    name: 'not-found',
    path: '/*',
    redirect: '/',
  },
]
