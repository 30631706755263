<i18n locale="en">
  {
    "title": "Frequently asked questions",
    "questions": {
      "cities": "What cities do you serve?",
      "keywords": "What are you known for?"
    }
  }
</i18n>

<template>
  <PreviewSheet
    class="faq-preview w-full h-full"
    background="primary"
    radius="0"
  >
    <div class="flex-col gap-8 justify-center">
      <PreviewTypography :text="t('title')" variant="heading2" />
      <div
        v-for="question in questions"
        :key="question"
        class="w-full flex-col"
      >
        <div class="w-full faq-preview__title flex-row justify-between">
          <PreviewTypography
            :text="t(`questions.${question}`)"
            align="left"
            variant="titleRegular"
          />
          <PreviewIcon :icon="PhCaretDown" color="secondary" size="16" />
        </div>
      </div>
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { PhCaretDown } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { PreviewIcon, PreviewSheet, PreviewTypography } from '@/lib/builder'

import type { FaqSection } from '../../../WebsiteBuilder.types'

defineProps<{
  content: FaqSection
}>()

const questions = ['cities', 'keywords']

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.faq-preview {
  padding: 120px;

  &__title {
    padding: 0 0 24px 0;
    border-bottom: 1px solid var(--background-divider);
  }

  &__collapse-caret {
    width: 16px;
    height: 16px;
    color: var(--text-color-secondary);
  }
}
</style>
