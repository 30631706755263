<template>
  <div class="flex-col gap-1">
    <button class="flex-row gap-4 justify-between" @click="isOpen = !isOpen">
      <span class="flex-row gap-2 align-center">
        <OwnType :text="label" color="primary" el="span" />
        <PhCaretUp v-if="isOpen" class="text-color-secondary" />
        <PhCaretDown v-else class="text-color-secondary" />
      </span>

      <OwnType
        v-if="!isOpen"
        :text="value"
        :tabular="currency"
        align="right"
        color="primary"
        el="span"
      />
    </button>

    <div v-if="isOpen" class="flex-col gap-1 pl-4">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PhCaretDown, PhCaretUp } from '@phosphor-icons/vue'
import { ref } from 'vue'

import { OwnType } from '@/ui'

withDefaults(
  defineProps<{
    currency?: boolean
    label: string
    value: string
  }>(),
  {
    currency: false,
  }
)

const isOpen = ref(false)
</script>
